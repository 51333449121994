import React from "react"
import Image from "../components/image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import { Container, Row, Col } from 'react-grid-system';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faDownload } from "@fortawesome/free-solid-svg-icons"

const team = [
    {
        name: "Dominik Ziegenhagel",
        title: "Lead Designer, Koordination",
        img: "domi2.jpg",
        url: "https://dominik.ziegenhagel.com"
    },
    {
        name: "Sven Müller",
        title: "Ausstattung, Maske, Licht",
        img: "sven_mueller.jpg"
    },
    {
        name: "Florian Görres",
        title: "Lead Marketing, Bild",
        img: "flo.jpg"
    },
    {
        name: "Elisa Mende",
        title: "Fotografie, Licht",
        img: "elisa.jpg"
    },
    {
        name: "Emanuel Leube",
        title: "Lead Technician, Bühne",
        img: "ema.jpg"
    },
    {
        name: "Nele Tümmler",
        title: "Ton, Sound Design ",
        img: "nele_tuemmler.jpg"
    }
    ]

const TeamPage = () => (
    <Layout container={false}>
        <SEO title="Unsere junges und kreatives Team" />

        <div className="page-header">
            <h1 className="text-center mt-4 mb-1">Team</h1>
            <p className="text-center">Hallo! Bonjour! Bona dies!</p>
        </div>

        <img style={{ width: "100%", position: "absolute" }} src={require("../images/divider/divider.svg")}></img>

        <Container>
            <Row className="nomargin-row" style={{"justifyContent":"center"}}>
                {
                    team.map(member => {

                        let section = (<>

                        <picture>
                            <source srcSet={require("../images/team/" + member.img)} type="image/jpeg"/>
                            <img src={require("../images/team/" + member.img)} className="member-img" />
                        </picture>


                            <h2>{member.name}</h2>
                            <p>{member.title}</p>
                        </>)

                        section = (member.url !== undefined) ? <a  className="hover-underline" href={member.url} target="_blank">{section}</a> : section

                        return <Col key={member.name} className="text-center" lg={4}>{section}</Col>

                    })
                }

            </Row>

        <br /><br /><br/><br/>
            <p class="text-small"> * Aus Gründen der Übersichtlichkeit listen wir unsere Dienste gesammelt auf und treten
    als Team von freischaffenden Kleingewerbler auf, die Hand in Hand arbeiten. Auch wenn die Zusammenarbeit und Absprache unter uns sehr gut funktioniert,
    arbeiten wir dennoch als unabhängige Freiberufler:innen, und sind in keiner gemeinsamen Unternehmensform eingeschrieben.  </p>
 
    </Container>


        <br /><br /><br/>
    </Layout>
)

export default TeamPage
